import {flagPathByCountryCode} from '@hconnect/uikit'
import CheckIcon from '@mui/icons-material/CheckSharp'
import {Alert, Box, Stack, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useAvailableCountries} from '../../../../Components/AccountForm/useCountries'
import {RequestAccessSelfOnboardingFormData} from '../../RequestAccessSelfOnboarding.types'

import {SummaryField} from './SummaryField'
import {SummarySection} from './SummarySection'
import {useStyles} from './SummaryStep.styles'
import {TermsAndConditions} from './TermsAndConditions'

type SummaryStepProps = {
  onCompanyEdit: () => void
  onAccessLevelEdit: () => void
  onDetailsEdit: () => void
  onTermsAccept?: (version: string) => void
}

export const SummaryStep = (props: SummaryStepProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const [termsVersion, setTermsVersion] = useState<string | null>(null)
  const availableCountries = useAvailableCountries()

  const formValues = formMethods.getValues()

  const country = availableCountries?.find(
    (country) => country.countryCode === formValues.countryId
  )

  const market = country?.markets.find((market) => market.marketId === formValues.market)
    ?.marketName

  useEffect(() => {
    if (termsVersion !== null && props.onTermsAccept !== undefined) {
      props.onTermsAccept(termsVersion)
    }
  }, [termsVersion, props])

  return (
    <Box
      style={{display: 'flex', flexDirection: 'column', gap: '16px', width: '100%'}}
      data-test-id="summaryStep"
    >
      <Typography className={classes.title} data-test-id="summaryStep-title">
        {t('authenticator.requestAccessSelfOnboarding.summaryStep.title')}
      </Typography>
      <SummarySection
        data-test-id="summaryStep-summarySection-company"
        title={t('authenticator.requestAccessSelfOnboarding.summaryStep.sections.company')}
        onClick={props.onCompanyEdit}
      />
      <Stack>
        <SummaryField
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.company')}
          value={formValues.companyName || '-'}
        />
        <Box className={classes.fieldContainer}>
          <SummaryField
            title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.country')}
            value={country?.countryName || '-'}
          />
          <SummaryField
            title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.market')}
            value={market || '-'}
          />
        </Box>
        <SummaryField
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.customerAccount')}
          value={formValues.customerIds.map((customerId) => customerId.id).join(', ') || '-'}
        />
        <SummarySection
          data-test-id="summaryStep-summarySection-accessLevel"
          title={t(
            'authenticator.requestAccessSelfOnboarding.summaryStep.sections.accessLevel.title'
          )}
          onClick={props.onAccessLevelEdit}
        />
        {Object.entries(formValues.permissions).map(
          ([permissionKey, isPermissionSelected]) =>
            isPermissionSelected && (
              <Stack
                key={permissionKey}
                direction="row"
                sx={{marginBottom: '16px', alignItems: 'center'}}
              >
                <CheckIcon className={classes.checkIcon} />
                <Typography variant="body1" className={classes.fieldValue}>
                  {t(
                    `authenticator.requestAccessSelfOnboarding.summaryStep.sections.accessLevel.permissionToggles.${permissionKey}`
                  )}
                </Typography>
              </Stack>
            )
        )}

        <Box className={classes.fieldContainer}>
          <SummaryField
            title={t(
              'authenticator.requestAccessSelfOnboarding.summaryStep.fields.accessToAllProjects'
            )}
            value={t(
              `authenticator.requestAccessSelfOnboarding.summaryStep.sections.accessLevel.${
                formValues.allProjectsAccess ? 'hasAccess' : 'hasNoAccess'
              }`
            )}
          />
          <SummaryField
            title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.accessToOnsite')}
            value={t(
              `authenticator.requestAccessSelfOnboarding.summaryStep.sections.accessLevel.${
                formValues.onsiteAccess ? 'hasAccess' : 'hasNoAccess'
              }`
            )}
          />
        </Box>
        <SummarySection
          data-test-id="summaryStep-summarySection-details"
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.sections.details')}
          onClick={props.onDetailsEdit}
        />
        <SummaryField
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.fullName')}
          value={formValues.fullName || '-'}
        />
        <SummaryField
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.emailAddress')}
          value={formValues.email || '-'}
        />
        <SummaryField
          title={t('authenticator.requestAccessSelfOnboarding.summaryStep.fields.mobilePhone')}
        >
          <Stack direction={'row'} alignItems={'center'}>
            {country && (
              <img
                src={flagPathByCountryCode(country.countryCode)}
                alt={`${country.countryCode}-flag`}
                style={{marginRight: '8px'}}
                width={'16px'}
                height={'12px'}
              />
            )}
            <Typography variant="body1" className={classes.fieldValue}>
              {formValues.mobileNumber || '-'}
            </Typography>
          </Stack>
        </SummaryField>
        <Box className={classes.termsConditionsContainer}>
          <TermsAndConditions country={formValues.countryId} onTermsSubmit={setTermsVersion} />
        </Box>
        <Alert
          variant="filled"
          severity="warning"
          className={classes.summaryAlert}
          classes={{icon: classes.summaryAlertIcon}}
        >
          {t('authenticator.requestAccessSelfOnboarding.summaryStep.alert')}
        </Alert>
      </Stack>
    </Box>
  )
}
