import EditIcon from '@mui/icons-material/EditOutlined'
import {Box, Stack, Typography} from '@mui/material'
import React from 'react'

import {useStyles} from './SummarySection.styles'

type SummarySectionProps = {
  title: string
  onClick: () => void
}

export const SummarySection: React.FC<SummarySectionProps> = (props) => {
  const {classes} = useStyles()

  return (
    <Stack direction="row" className={classes.summarySection}>
      <Typography className={classes.sectionTitle}>{props.title}</Typography>
      <Stack direction="row" className={classes.editContainer} onClick={props.onClick}>
        <EditIcon fontSize="small" />
        <Box sx={{marginLeft: '8px'}}>Edit</Box>
      </Stack>
    </Stack>
  )
}
