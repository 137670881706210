import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {routes} from '../../routes'
export const LinkToSignIn = () => {
  const {t} = useTranslation()
  const history = useHistory()
  return (
    <div style={{marginTop: '20px'}}>
      <span style={{fontSize: '16px', color: '#435A70'}}>
        {t('authenticator.requestAccess.redirectText')}
      </span>
      <span
        data-test-id="sign-in-link"
        style={{
          paddingLeft: '10px',
          color: '#016AD4',
          fontSize: '16px',
          fontWeight: 500,
          cursor: 'pointer'
        }}
        onClick={() => history.push({pathname: routes.SignIn, search: window.location.search})}
      >
        {t('authenticator.requestAccess.redirectLink')}
      </span>
    </div>
  )
}
