import {InputTextField} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../../RequestAccessSelfOnboarding.types'
import {useStyles} from '../AccessLevelStep.styles'

export const InvoiceInput = () => {
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const errors = formMethods.formState.errors
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <>
      <Controller
        name="invoiceNr"
        control={formMethods.control}
        render={({field}) => (
          <InputTextField
            data-test-id="raf-invoice-nr"
            label={t(
              'authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.invoiceNumber'
            )}
            wrapperClassName={classes.inputTextWrapper}
            helperText={
              errors.invoiceNr && t(`authenticator.errorMessages.${errors.invoiceNr.message}`)
            }
            {...field}
          />
        )}
      />
      <Controller
        name="invoiceNetTotal"
        control={formMethods.control}
        render={({field}) => (
          <InputTextField
            data-test-id="raf-invoice-net-total"
            label={t(
              'authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.invoiceNetTotal'
            )}
            wrapperClassName={classes.inputTextWrapper}
            helperText={
              errors.invoiceNetTotal &&
              t(`authenticator.errorMessages.${errors.invoiceNetTotal.message}`)
            }
            {...field}
          />
        )}
      />
    </>
  )
}
