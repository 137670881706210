import {useBreakPoints} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../styles'

import {DeliveryInput} from './inputs/DeliveryInput'
import {InvoiceInput} from './inputs/InvoiceInput'

export const AdditionalDetails = () => {
  const {t} = useTranslation()
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const {classes: generalClasses} = useRafStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs'].includes(screenSizes)
  const currentPermissions = formMethods.watch('permissions')
  const isCanSeeInvoicesSelected = currentPermissions['canSeeInvoices']

  return (
    <Box data-test-id="raf-access-level-additional-details">
      <Typography
        className={generalClasses.subTitle}
        style={{marginBottom: '8px', marginTop: '32px'}}
      >
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.title')}
      </Typography>
      <Typography className={generalClasses.description} style={{marginBottom: '16px'}}>
        {t(
          'authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.description'
        )}
      </Typography>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'start',
          gap: '16px',
          marginBottom: '32px'
        }}
      >
        {isCanSeeInvoicesSelected ? <InvoiceInput /> : <DeliveryInput />}
      </Box>
    </Box>
  )
}
