import {InputTextField} from '@hconnect/uikit/src/lib2'
import {TextFieldProps} from '@mui/material'
import queryString from 'query-string'
import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {FieldValues, Path, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {useGlobalState} from '../../hooks/useGlobalState'

import {useAvailableCountries} from './useCountries'

export type CountryFieldProps<T extends FieldValues> = TextFieldProps & {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  formMethods: UseFormReturn<T>
  name: Path<T>
  disabled?: boolean
}

export const CountryField = <T extends FieldValues>({
  name,
  value,
  onChange,
  disabled,
  formMethods,
  ...otherProps
}: CountryFieldProps<T>) => {
  const availableCountries = useAvailableCountries()
  const {globalState} = useGlobalState()
  const {t} = useTranslation()
  const location = useLocation()
  const {icc} = queryString.parse(location.search)
  const [preselectedCountry, setPreselectedCountry] = useState(icc?.toString())
  const countrySelectValues = availableCountries?.map((country) => ({
    value: country.countryCode,
    label: t(`authenticator.requestAccess.countries.${country.countryCode}`)
  }))

  countrySelectValues?.sort((a, b) => (a.label < b.label ? -1 : 1))

  useEffect(() => {
    const foundCountry = countrySelectValues?.find((country) => country.value === icc)?.value

    setPreselectedCountry(foundCountry)

    foundCountry && preselectedCountry && formMethods.setValue(name, preselectedCountry as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedCountry])

  return (
    <InputTextField
      id="countryField"
      name={name}
      value={preselectedCountry || value}
      onChange={onChange}
      error={!!formMethods.formState.errors?.[name]}
      helperText={
        !!formMethods.formState.errors?.[name] &&
        t(`authenticator.errorMessages.${formMethods.formState.errors?.[name]?.message}`)
      }
      select
      label={t('authenticator.requestAccess.textfieldLabels.country')}
      disabled={disabled || !!preselectedCountry || !!globalState.country}
      selectValues={countrySelectValues || []}
      {...otherProps}
    />
  )
}
