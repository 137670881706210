import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  fieldTitle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    color: '#52667A'
  },
  fieldValue: {
    fontWeight: '500'
  }
}))
