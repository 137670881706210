import {useTranslation} from 'react-i18next'

import {StepForm} from '../../../Components/SelfOnBoarding/StepForm'
import {useGlobalState} from '../../../hooks/useGlobalState'
import {RequestAccessSelfOnboardingFormData} from '../RequestAccessSelfOnboarding.types'
import {AccessLevelStep} from '../steps/accessLevel/AccessLevelStep'
import {CompanyStep} from '../steps/company/CompanyStep'
import {RegistrationStep} from '../steps/registration/RegistrationStep'
import {SummaryStep} from '../steps/summary/SummaryStep'
import {UserDetails} from '../steps/userDetails/UserDetails'

import {StepContainer} from './StepContainer'

type FormStepperProps = {onSubmit: () => void; isLoading: boolean}

export const FormStepper = ({onSubmit, isLoading}: FormStepperProps) => {
  const {t} = useTranslation()
  const {globalState, setGlobalState} = useGlobalState()

  const index = globalState.requestAccessStepperIndex

  const handleSummaryEditClick = (index: number) => {
    setGlobalState((g) => ({
      ...g,
      requestAccessStepperIndex: index
    }))
  }

  const steps = [
    {
      stepComponent: <RegistrationStep />,
      stepNumber: 1
    },
    {
      stepComponent: <CompanyStep />,
      submitText: t('authenticator.requestAccessSelfOnboarding.companyStep.form.setAccessLevel'),
      stepNumber: 2,
      stepFieldNames: [
        'companyName',
        'countryId',
        'market',
        'customerIds'
      ] as (keyof RequestAccessSelfOnboardingFormData)[]
    },
    {
      stepComponent: <AccessLevelStep />,
      submitText: t('authenticator.requestAccessSelfOnboarding.accessLevelStep.fillDetails'),
      stepNumber: 3,
      stepFieldNames: [
        'permissions',
        'comment',
        'allProjectsAccess',
        'onsiteAccess',
        'hubAccess',
        'deliveryTicketNr',
        'deliveryTicketDate',
        'invoiceNr',
        'invoiceNetTotal'
      ] as (keyof RequestAccessSelfOnboardingFormData)[]
    },
    {
      stepComponent: <UserDetails />,
      submitText: t('authenticator.requestAccessSelfOnboarding.userDetailsStep.goToSummary'),
      stepNumber: 4,
      stepFieldNames: [
        'fullName',
        'email',
        'mobileNumber'
      ] as (keyof RequestAccessSelfOnboardingFormData)[]
    },
    {
      stepComponent: (
        <SummaryStep
          onCompanyEdit={() => handleSummaryEditClick(1)}
          onAccessLevelEdit={() => handleSummaryEditClick(2)}
          onDetailsEdit={() => handleSummaryEditClick(3)}
        />
      ),
      submitText: t('authenticator.requestAccessSelfOnboarding.summaryStep.submit'),
      onSubmit: onSubmit,
      stepNumber: 5
    }
  ]

  return (
    <>
      {steps.map(
        (step) =>
          step.stepNumber === index + 1 && (
            <StepContainer
              key={index}
              form={step.stepComponent}
              stepForm={
                <StepForm
                  submitText={step.submitText}
                  onSubmit={step.onSubmit}
                  stepFieldNames={step.stepFieldNames}
                  isLoading={isLoading}
                />
              }
            />
          )
      )}
    </>
  )
}
