import {Box} from '@mui/material'
import {ReactNode} from 'react'

type StepContainerProps = {form: ReactNode; stepForm?: ReactNode}

export const StepContainer = ({form, stepForm}: StepContainerProps) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      {form} {stepForm}
    </Box>
  )
}
