import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useRafStyles} from '../styles'

import {ReactComponent as CompanyCustomerHint} from '@hconnect/authenticator/src/Assets/raf_company_customer_hint.svg'

type CustomerNumberHintDialogProps = {
  isOpened: boolean
  setIsOpened: (isOpened: boolean) => void
}
export const CustomerNumberHintDialog = ({
  isOpened,
  setIsOpened
}: CustomerNumberHintDialogProps) => {
  const {t} = useTranslation()
  const {classes: generalClasses} = useRafStyles()

  return (
    <Dialog open={isOpened}>
      <DialogTitle style={{fontSize: '20px', fontWeight: 600, color: '#29333D'}}>
        {t(
          'authenticator.requestAccessSelfOnboarding.companyStep.form.customerNumberLink.dialogTitle'
        )}
      </DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <DialogContentText style={{color: '#52667A'}}>
          {t(
            'authenticator.requestAccessSelfOnboarding.companyStep.form.customerNumberLink.dialogContent'
          )}
        </DialogContentText>
        <CompanyCustomerHint style={{alignSelf: 'center'}} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsOpened(false)}
          className={generalClasses.confirmButton}
          color="primary"
        >
          {t(
            'authenticator.requestAccessSelfOnboarding.companyStep.form.customerNumberLink.dialogConfirm'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
