import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D',
    marginBottom: '16px'
  },
  summaryAlert: {
    border: '1px solid #FCEED9',
    borderLeft: '4px solid #EE8B00',
    background: '#FEF9F2',
    color: '#29333D',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: '400',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  summaryAlertIcon: {
    color: '#EE8B00'
  },
  fieldContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  termsConditionsContainer: {
    margin: '20px 0px 32px'
  },
  checkIcon: {
    color: '#016AD4',
    marginRight: '8px',
    width: '16px',
    height: '16px'
  },
  fieldValue: {
    fontWeight: 500
  }
}))
