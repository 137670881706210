import {Product} from '@hconnect/apiclient'
import {getBrandingFromUrl} from '@hconnect/common/branding/Branding.utils'
import {useBreakPoints} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import {Box, Paper} from '@mui/material'
import {SnackbarProvider} from 'notistack'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useLocation} from 'react-router-dom'

import {useAppStyles} from '../../App.styles'
import {useAuthFeatures} from '../../hooks/features/useAuthFeatures'
import {useGlobalState} from '../../hooks/useGlobalState'
import {ErrorPage} from '../../Pages/ErrorPage'
import {routes} from '../../routes'
import {AnnouncementMessage} from '../AnnouncementMessage/AnnouncementMessage'
import {AuthHeader} from '../AuthHeader/AuthHeader'
import {ProductAndBrandingLogos} from '../ProductAndBrandingLogos'
import {RequestAccessStepper} from '../SelfOnBoarding/RequestAccessStepper'

type WrapperProps = {
  children: React.ReactNode
}
export const Wrapper = ({children}: WrapperProps) => {
  const {classes} = useAppStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {globalState} = useGlobalState()
  const {shouldDisplayAnnouncementBanner, shouldDisplaySelfOnboardingRAF} = useAuthFeatures()
  const {pathname} = useLocation()
  const isCarbonBank = globalState.clientId === Product.CarbonBank

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      maxSnack={1}
    >
      <Box className={isCarbonBank ? classes.cbRootContainer : classes.rootContainer}>
        <ErrorBoundary FallbackComponent={({error}) => <ErrorPage error={error.message} />}>
          <Shell
            compact={false}
            isResponsive
            boxed={false}
            onDark={false}
            showScrollToTop={false}
            stickyFooter={!isMobile}
            zIndex={1002}
            header={<AuthHeader />}
            appBarSx={isCarbonBank ? {backgroundColor: 'rgba(242, 245, 247, 1)'} : undefined}
          >
            {shouldDisplayAnnouncementBanner && <AnnouncementMessage />}
            <Box className={classes.authMoleculeContainer}>
              <Box className={classes.authMolecule} data-test-id="authenticator-form">
                {shouldDisplaySelfOnboardingRAF && pathname === routes.RequestAccess ? (
                  <RequestAccessStepper />
                ) : null}
                <Paper
                  className={classes.paper}
                  elevation={8}
                  style={{
                    width:
                      pathname !== routes.RequestAccess &&
                      pathname !== routes.CreateAccount &&
                      pathname !== routes.RequestAccessSuccess
                        ? '450px'
                        : '650px',
                    boxShadow: 'none',
                    padding: pathname === routes.RequestAccess ? '24px' : '48px',
                    borderTop: isCarbonBank ? undefined : '1px solid rgba(0, 39, 77, 0.15)'
                  }}
                >
                  {globalState.clientId !== Product.OnSite &&
                    pathname !== routes.RequestAccessSuccess && (
                      <ProductAndBrandingLogos
                        branding={getBrandingFromUrl(globalState.redirectUrl)}
                      />
                    )}
                  {children}
                </Paper>
              </Box>
            </Box>
          </Shell>
        </ErrorBoundary>
      </Box>
    </SnackbarProvider>
  )
}
