import CheckIcon from '@mui/icons-material/Check'
import {Box, StepButton, StepIcon, StepIconProps} from '@mui/material'
import Step from '@mui/material/Step'
import StepConnector from '@mui/material/StepConnector'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {useGlobalState} from '../../hooks/useGlobalState'

import {useStyles} from './RequestAccessStepper.styles'

export const RequestAccessStepper = () => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const {globalState, setGlobalState} = useGlobalState()
  const stepRefs = useRef<(HTMLDivElement | null)[]>([])

  const steps = [
    t('authenticator.requestAccessSelfOnboarding.stepper.registration'),
    t('authenticator.requestAccessSelfOnboarding.stepper.yourCompany'),
    t('authenticator.requestAccessSelfOnboarding.stepper.accessLevel'),
    t('authenticator.requestAccessSelfOnboarding.stepper.yourDetails'),
    t('authenticator.requestAccessSelfOnboarding.stepper.summary')
  ]

  const activeStep = globalState.requestAccessStepperIndex

  const onStepClick = (index: number) => {
    setGlobalState((g) => ({
      ...g,
      requestAccessStepperIndex: index
    }))
  }

  const CustomStepIcon = (props: StepIconProps) => {
    return props.completed ? (
      <Box
        style={{
          backgroundColor: '#00AB50',
          border: '1px solid rgba(102, 205, 150, 0.40)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '40px',
          width: '40px'
        }}
      >
        <CheckIcon style={{fontSize: '16px'}} />
      </Box>
    ) : (
      <StepIcon {...props} />
    )
  }

  useEffect(() => {
    if (stepRefs.current[activeStep]) {
      stepRefs.current[activeStep]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest'
      })
    }
  }, [activeStep])

  return (
    <Box style={{overflowX: 'hidden', display: 'flex', justifyContent: 'center'}}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnector classes={{root: classes.connector}} />}
        classes={{root: classes.stepper}}
      >
        {steps.map((label, index) => (
          <Step key={label} ref={(el) => (stepRefs.current[index] = el)}>
            <StepButton onClick={() => onStepClick(index)}>
              <StepLabel
                classes={{
                  label: classes.label,
                  active: classes.labelActive
                }}
                StepIconProps={{
                  classes: {
                    root: classes.stepIcon,
                    active: classes.stepIconActive,
                    text: classes.stepIconText
                  }
                }}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
