import {Product} from '@hconnect/apiclient'
import cbLogo from '@hconnect/common/branding/branding/icons/cbLogo.svg'
import LogoHM from '@hconnect/common/branding/branding/icons/Logo-HM.svg'
import {Branding} from '@hconnect/common/branding/Branding.types'
import LogoHub from '@hconnect/uikit/src/common/assets/Hub-Logo.svg'
import LogoHConnect from '@hconnect/uikit/src/common/assets/LogoHConnectNew.svg'
import LogoHProduce from '@hconnect/uikit/src/common/assets/LogoHproduce_dark_green.svg'
import LogoMHC from '@hconnect/uikit/src/common/assets/LogoMHC.svg'
import LogoOnsite from '@hconnect/uikit/src/common/assets/LogoOnsite.svg'
import classNames from 'classnames'

import {useLayoutStyles} from '../App.styles'
import {useGlobalState} from '../hooks/useGlobalState'

import {useStyles} from './ProductAndBrandingLogos.styles'

export const calcLogoFromProductName = (product: string) => {
  switch (product.toLowerCase()) {
    case 'carbonbank':
      return cbLogo
    case 'onsite':
      return LogoOnsite
    case 'hub':
      return LogoHub
    case 'hconnect':
      return LogoMHC
    case 'hcem':
    case 'cockpit':
    case 'maintain':
    case 'pom':
    case 'settings':
    case 'hproduce':
      return LogoHProduce
    default:
      return LogoHConnect
  }
}

type ProductAndBrandingLogosProps = {
  branding: Branding
}

export const ProductAndBrandingLogos = ({branding}: ProductAndBrandingLogosProps) => {
  const {classes} = useStyles()
  const {classes: lc} = useLayoutStyles()
  const isHeidelbergMaterials = window.location.origin.toLowerCase().includes('heidelbergmaterials')
  const isNewHeidelbergMaterialsURL =
    window.location.origin.toLowerCase().includes('hconnect.digital') ||
    window.location.origin.toLowerCase().includes('hproduce.digital')
  const {globalState} = useGlobalState()

  const isCarbonBank = globalState.clientId === Product.CarbonBank
  const formattedBranding = branding.nationalLogoUrl?.includes('Logo-HM-Compact-Negative')
    ? LogoHM
    : branding.onWhiteLogoUrl || branding.nationalLogoUrl
  if (branding.nationalLogoUrl) {
    return (
      <div className={classNames(lc.flx, lc.flxRow, lc.jcc, lc.aic, classes.container)}>
        <div style={{width: '200px'}}>
          <img
            alt="branding logo"
            className={classNames(classes.pictureDouble)}
            src={
              isCarbonBank
                ? cbLogo
                : branding?.nationalLogoUrl === 'nationalBrandName' &&
                    (isHeidelbergMaterials || isNewHeidelbergMaterialsURL)
                  ? LogoHM
                  : formattedBranding
            }
            data-test-id="img-branding"
          />
        </div>
      </div>
    )
  }
  return null
}
