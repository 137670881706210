// Map HTTP STATUS_CODE and TYPE in the response body to a unique key
// that can be used to get a translation, e.g. t('errorMessages.400_PROBLEM_CREATING_USER')
// See https://api.hce.heidelbergcement.com/API#header-error-object

export const InternalErrors = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNMAPPED_STATUS_AND_TYPE: 'UNMAPPED_STATUS_AND_TYPE',
  NETWORK_ERROR: 'NETWORK_ERROR', // the server did not respond at all and therefore did not send status and type
  BAD_TOKENINFO_ERROR: 'BAD_TOKENINFO_ERROR', // the jwt is bad
  FIELD_REQUIRED: 'FIELD_REQUIRED',
  PHONE_OR_EMAIL_REQUIRED: 'PHONE_OR_EMAIL_REQUIRED',
  PASSWORDS_MUST_MATCH: 'PASSWORDS_MUST_MATCH',
  PASSWORD_REQUIREMENTS: 'PASSWORD_REQUIREMENTS',
  INVALID_MOBILE_NUMBER: 'INVALID_MOBILE_NUMBER',
  INVALID_EMAIL: 'INVALID_EMAIL',
  LOGIN_FAILURE_AFTER_REGISTRATION: 'LOGIN_FAILURE_AFTER_REGISTRATION',
  FIELD_NUMBER: 'FIELD_NUMBER',
  MAX_CHARACTERS_EXCEEDED: 'MAX_CHARACTERS_EXCEEDED'
}

export const BackendValidationError = 'https://api.hce.heidelbergcement.com/errors/validationError'

export type BackendFieldError = {
  fieldName: string
  validationErrorType: string
}

// See docs https://api.hce.heidelbergcement.com/API#header-errors
export const HttpStatusAndErrorTypeToUniqueKey = {
  301: {DEFAULT: '301_MOVED_PERMANENTLY'},

  400: {
    DEFAULT: '400_BAD_REQUEST',
    'https://api.hce.heidelbergcement.com/errors/requestError': '400_REQUEST_ERROR',
    'https://api.hce.heidelbergcement.com/errors/problemCreatingUser': '400_PROBLEM_CREATING_USER',
    'https://api.hce.heidelbergcement.com/errors/login-failure': '400_LOGIN_FAILURE',
    'https://api.hce.heidelbergcement.com/errors/updateUser-emailInUse':
      '400_UPDATE_USER_EMAIL_IN_USE',
    'https://api.hce.heidelbergcement.com/errors/resetPassword': '400_RESET_PASSWORD',
    'https://api.hce.heidelbergcement.com/errors/changePassword': '400_CHANGE_PASSWORD',
    'https://api.hce.heidelbergcement.com/errors/getuser': '400_GET_USER'
  },

  401: {
    DEFAULT: '401_UNAUTHORIZED',
    'https://api.hce.heidelbergcement.com/errors/sharePermissionsFailed':
      '401_SHARE_PERMISSIONS_FAILED',
    'https://api.hce.heidelbergcement.com/errors/login-failure': '401_LOGIN_FAILURE',
    'https://api.hce.heidelbergcement.com/errors/refresh-failure': '401_REFRESH_FAILURE',
    'https://api.hce.heidelbergcement.com/errors/account-locked': '401_ACCOUNT_LOCKED'
  },

  403: {
    DEFAULT: '403_FORBIDDEN',
    'https://api.hce.heidelbergcement.com/errors/userAlreadyExists': '403_USER_ALREADY_EXISTS',
    'https://api.hce.heidelbergcement.com/errors/createUser': '403_USER_ALREADY_EXISTS', // this response is not according to API-spec (https://jira.grouphc.net/browse/DF-1224)
    'https://api.hce.heidelbergcement.com/errors/changePassword': '403_CHANGE_PASSWORD',
    'https://api.hce.heidelbergcement.com/errors/getuser': '403_GET_USER',
    'https://api.hce.heidelbergcement.com/errors/passwordpolicy': '403_PASSWORD_POLICY'
  },

  404: {
    DEFAULT: '404_NOT_FOUND',
    'https://api.hce.heidelbergcement.com/errors/resetPassword': '404_RESET_PASSWORD',
    'https://api.hce.heidelbergcement.com/errors/changePassword': '404_CHANGE_PASSWORD',
    'https://api.hce.heidelbergcement.com/errors/getuser': '404_GET_USER'
  },

  429: {DEFAULT: '429_TOO_MANY_REQUESTS'},
  500: {
    DEFAULT: '500_INTERNAL_SERVER_ERROR'
  },

  502: {DEFAULT: '502_BAD_GATEWAY'},
  503: {DEFAULT: '503_SERVICE_UNAVAILABLE'},
  504: {DEFAULT: '504_GATEWAY_TIMEOUT'}
}
