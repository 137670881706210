export enum Product {
  OnSite = 'OnSite',
  Cockpit = 'Cockpit',
  Hub = 'Hub',
  TruckMonitor = 'TruckMonitor',
  HConnect = 'HConnect',
  UserManagement = 'UserManagement',
  HCEM = 'HCEM',
  HProduce = 'HProduce',
  Maintain = 'Maintain',
  POM = 'POM',
  Slot = 'Slot',
  Local = 'local',
  Invitation = 'invitation',
  Settings = 'Settings'
}

export enum LoginType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export type RedirectReason = 'LOGIN' | 'REQUEST_ACCESS' | 'CREATE_ACCOUNT'

// eslint-disable-next-line no-useless-escape
export const EmailRegExp = /^[\w!#$%&'*+-./=?^`{|}~’]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/
export const PhoneRegExp = /^\+*\d*$/

export const statusCodeToAction = {
  301: 'report',
  400: 'report',
  401: 'login',
  403: 'request',
  404: 'report',
  429: 'report',
  500: 'report',
  502: 'retry',
  503: 'report',
  504: 'retry'
} as const

export const clientSecrets: Record<Product, string> = {
  [Product.OnSite]: 'B84D8A70-A0FF-4597-A72E-3895341D2F24',
  [Product.Hub]: '4E341E37-91B8-4536-960E-80DEF6A911EB',
  [Product.TruckMonitor]: '511536EF-F270-4058-80CA-1C89C192F69A',
  [Product.HConnect]: 'BE3A54F8-FECD-48C7-A0A5-079EFD78819A',
  [Product.UserManagement]: 'BE3A54F8-FECD-48C7-A0A5-079EFD78819A',
  [Product.HCEM]: '14440DF9-CBC3-4131-B011-DE4523464DF5',
  [Product.HProduce]: 'new', // seams to me like this setting is outdated and now handled in the identity server?!
  [Product.Cockpit]: 'ED7A9C54-5EC6-4699-A611-F78FDD336C7F',
  [Product.Local]: '6A6EBB31-95C0-4AD6-6B68-2F4AB0886B65',
  [Product.POM]: 'obsolete', // client secrets are no longer needed,
  [Product.Maintain]: 'obsolete', // client secrets are no longer needed,
  [Product.Slot]: 'obsolete',
  [Product.Invitation]: 'new',
  [Product.Settings]: 'obsolete'
}

export const requestAccessMaxStepIndex = 4
