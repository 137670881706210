import {InputTextField} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../../RequestAccessSelfOnboarding.types'
import {useStyles} from '../AccessLevelStep.styles'

export const DeliveryInput = () => {
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const errors = formMethods.formState.errors
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <>
      <Controller
        name="deliveryTicketNr"
        control={formMethods.control}
        render={({field}) => (
          <InputTextField
            data-test-id="raf-delivery-ticket-nr"
            label={t(
              'authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.deliveryNumber'
            )}
            wrapperClassName={classes.inputTextWrapper}
            helperText={
              errors.deliveryTicketNr &&
              t(`authenticator.errorMessages.${errors.deliveryTicketNr.message}`)
            }
            {...field}
          />
        )}
      />
      <Controller
        name="deliveryTicketDate"
        control={formMethods.control}
        render={({field}) => (
          <InputTextField
            data-test-id="raf-delivery-ticket-date"
            label={t(
              'authenticator.requestAccessSelfOnboarding.accessLevelStep.additionalDetails.deliveryDate'
            )}
            type="date"
            InputLabelProps={{shrink: true}}
            wrapperClassName={classes.inputTextWrapper}
            {...field}
          />
        )}
      />
    </>
  )
}
