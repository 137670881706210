import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  stepper: {
    width: '700px',
    maxWidth: '100%',
    margin: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      display: 'flex',
      margin: '16px 0',
      overflowY: 'hidden',
      overflowX: 'hidden'
    }
  },
  label: {
    whiteSpace: 'nowrap',
    color: 'rgba(255, 255, 255, 0.60)',
    '&.Mui-active': {
      color: '#FFFFFF',
      fontWeight: 600
    },
    '&.Mui-completed': {
      color: 'rgba(255, 255, 255, 0.60)',
      fontWeight: 400
    }
  },
  labelActive: {
    fontWeight: 500
  },
  connector: {
    top: '20px',
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
    '& .MuiStepConnector-line': {
      borderColor: 'rgba(255, 255, 255, 0.25)' // Change this to your desired color
    }
  },
  stepIconActive: {
    fill: '#141A1F',
    borderRadius: '50%',
    fontSize: '24px',
    width: '40px',
    height: '40px',
    opacity: 1
  },
  stepIconCompleted: {
    fill: '#00AB50',
    fontSize: '24px',
    width: '40px',
    height: '40px',
    opacity: 1
  },
  stepIcon: {
    fill: '#01407F',
    borderRadius: '50%',
    fontSize: '24px',
    width: '40px',
    height: '40px',
    opacity: 0.5
  },
  stepIconText: {
    fontSize: '10px'
  }
}))
