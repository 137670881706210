import {InputTextField} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../styles'

import {MobileNumberInput} from './inputs/MobileNumberInput'

export const UserDetails = () => {
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const {t} = useTranslation()
  const {classes: generalClasses} = useRafStyles()

  return (
    <Box style={{width: '100%', marginBottom: '12px'}}>
      <Typography className={generalClasses.title} style={{marginBottom: '32px'}}>
        {t('authenticator.requestAccessSelfOnboarding.userDetailsStep.title')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        <Controller
          name="fullName"
          control={formMethods.control}
          render={({field}) => (
            <InputTextField
              id="fullNameField"
              data-test-id="raf-user-details-full-name"
              label={t('authenticator.requestAccessSelfOnboarding.userDetailsStep.fullName')}
              type="text"
              error={!!formMethods.formState.errors.fullName}
              helperText={
                !!formMethods.formState.errors.fullName &&
                t(`authenticator.errorMessages.${formMethods.formState.errors.fullName?.message}`)
              }
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={formMethods.control}
          render={({field}) => (
            <InputTextField
              id="emailField"
              data-test-id="raf-user-details-email"
              error={!!formMethods.formState.errors.email}
              label={t('authenticator.requestAccessSelfOnboarding.userDetailsStep.email')}
              type="email"
              helperText={
                !!formMethods.formState.errors.email &&
                t(`authenticator.errorMessages.${formMethods.formState.errors.email?.message}`)
              }
              {...field}
            />
          )}
        />
        <Controller
          name="mobileNumber"
          control={formMethods.control}
          render={({field: {value, onChange, name, ref}}) => (
            <MobileNumberInput
              ref={ref}
              data-test-id="raf-user-details-mobile-number"
              id="mobileNumberField"
              name={name}
              value={value || ''}
              onChange={onChange}
              formMethods={formMethods}
              placeholder={t(
                'authenticator.requestAccessSelfOnboarding.userDetailsStep.mobileNumber'
              )}
              options={{initialCountry: formMethods.getValues().countryId, separateDialCode: false}}
            />
          )}
        />
      </Box>
    </Box>
  )
}
