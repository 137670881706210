import {PhoneNumberTextField} from '@hconnect/uikit/src/lib2'
import React, {useState} from 'react'
import {FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {InternalErrors} from '../../ErrorMap'

import {AccountFormComponentProps, AccountFormTypes, AccountFormValues} from './types'

type MobilePhoneFieldProps<T> = AccountFormComponentProps & {
  name: keyof AccountFormValues
  id: string
  initialCountry: string
  value: string
  onChange: (number: string) => void
  required: boolean
  ref: React.Ref<HTMLInputElement>
  formMethods: T
}

export const MobilePhoneField = <T extends FieldValues>({
  id,
  name,
  initialCountry,
  value,
  onChange,
  formMethods: {setError, clearErrors, formState},
  required,
  ref
}: MobilePhoneFieldProps<T>) => {
  const {t} = useTranslation()
  const hasError = !!formState.errors.mobileNumber?.message
  const locaKey = `authenticator.errorMessages.${formState.errors.mobileNumber?.message}`
  const helperText = hasError ? t(locaKey) : undefined
  const [displayMobileError, setDisplayMobileError] = useState(false)

  return (
    <PhoneNumberTextField
      id={id}
      ref={ref}
      name={name}
      data-test-id="phone-input"
      fullWidth
      helperText={helperText}
      error={hasError}
      onPhoneChange={(phoneNumber, isValidNumber) => {
        onChange(phoneNumber)
        setDisplayMobileError(!isValidNumber)
      }}
      options={{initialCountry}}
      InputLabelProps={{
        shrink: false,
        style: {
          fontSize: '14px',
          fontWeight: 500
        }
      }}
      InputProps={{
        style: {
          fontSize: 14
        }
      }}
      onFocusCapture={() => {
        clearErrors(name)
      }}
      onBlurCapture={() => {
        if (value && displayMobileError) {
          setError(name, {
            type: 'notValidNumber',
            message: InternalErrors.INVALID_MOBILE_NUMBER
          })
        }
      }}
      required={required}
      placeholder={t('authenticator.requestAccess.textfieldLabels.mobileNumber')}
    />
  )
}
