import {PhoneNumberTextField} from '@hconnect/uikit/src/lib2'
import {TextFieldProps} from '@mui/material'
import intlTelInput from 'intl-tel-input'
import React, {useState} from 'react'
import {FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  AccountFormComponentProps,
  AccountFormValues
} from '../../../../../Components/AccountForm/types'
import {InternalErrors} from '../../../../../ErrorMap'

type MobilePhoneInputProps<T> = TextFieldProps &
  AccountFormComponentProps & {
    name: keyof AccountFormValues
    id: string
    value: string
    onChange: (number: string) => void
    ref: React.Ref<HTMLInputElement>
    formMethods: T
    options?: intlTelInput.Options
  }

export const MobileNumberInput = <T extends FieldValues>({
  formMethods: {setError, clearErrors, formState},
  id,
  name,
  onChange,
  ref,
  value,
  options,
  ...otherProps
}: MobilePhoneInputProps<T>) => {
  const {t} = useTranslation()
  const locaKey = `authenticator.errorMessages.${formState.errors.mobileNumber?.message}`

  const hasError = !!formState.errors.mobileNumber?.message
  const [displayMobileError, setDisplayMobileError] = useState(false)

  const helperText = hasError ? t(locaKey) : undefined
  return (
    <PhoneNumberTextField
      ref={ref}
      id={id}
      data-test-id="raf-user-details-mobile-number"
      name={name}
      fullWidth
      helperText={helperText}
      error={hasError}
      onPhoneChange={(phoneNumber, isValidNumber) => {
        onChange(phoneNumber)
        setDisplayMobileError(!isValidNumber)
      }}
      options={options}
      InputProps={{
        style: {
          fontSize: '16px',
          fontWeight: 500
        }
      }}
      onFocusCapture={() => {
        clearErrors(name)
      }}
      onBlurCapture={() => {
        if (value && displayMobileError) {
          setError(name, {
            type: 'notValidNumber',
            message: InternalErrors.INVALID_MOBILE_NUMBER
          })
        }
      }}
      keepDialCode={true}
      newInput={true}
      {...otherProps}
    />
  )
}
