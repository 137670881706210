export type RequestAccessSelfOnboardingFormData = {
  permissions: PermissionType
  companyName: string
  market: string
  countryId: string
  customerIds: Customer[]
  email?: string
  mobileNumber?: string
  comment?: string
  allProjectsAccess?: boolean
  onsiteAccess?: boolean
  hubAccess?: boolean
  deliveryTicketNr?: string
  deliveryTicketDate?: string
  invoiceNr?: string
  invoiceNetTotal?: string
  fullName?: string
}

export type PermissionType = {
  [permission: string]: boolean
}

type Customer = {
  id: string
}

export const ContactUsUrl =
  'https://forms.office.com/pages/responsepage.aspx?id=BiSVVyivyEO03qTgb1dHbXPkKU6CsC5MsqIt5o24w4hUMzFHNjYwSVhYQTBVNFJMWEdKRlNRQjJSOCQlQCN0PWcu&route=shorturl'
