import {FormToggle} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../../RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../../styles'

export const HubAccessInput = () => {
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const {t} = useTranslation()
  const {classes: generalClasses} = useRafStyles()
  const currentPermissions = formMethods.watch('permissions')
  const isOrderPlacerSelected = currentPermissions['canCreateAndChangeOrders']

  return (
    <>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: isOrderPlacerSelected ? '16px' : 0
        }}
      >
        <Typography className={generalClasses.subTitle}>
          {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.hubAccessTitle')}
        </Typography>
        <Controller
          name="hubAccess"
          control={formMethods.control}
          render={({field}) => (
            <FormToggle
              toggleKey="hub-access"
              label=""
              data-test-id={'hub-access-toggle'}
              checked={field.value as boolean}
              onChange={field.onChange}
            />
          )}
        />
      </Box>
      <Typography className={generalClasses.description} style={{marginBottom: '24px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.hubAccessDescription')}
      </Typography>
    </>
  )
}
