import {InputTextField} from '@hconnect/uikit/src/lib2'
import {Add, DeleteOutline} from '@mui/icons-material'
import {Box, Button, IconButton, Typography} from '@mui/material'
import React, {useState} from 'react'
import {Controller, useFieldArray, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CountryField} from '../../../../Components/AccountForm/CountryField'
import {MarketField} from '../../../../Components/AccountForm/MarketField'
import {useGlobalState} from '../../../../hooks/useGlobalState'
import {CustomerNumberHintDialog} from '../../dialogs/CustomerNumberHintDialog'
import {RequestAccessSelfOnboardingFormData} from '../../RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../styles'

export const CompanyStep = () => {
  const {t} = useTranslation()
  const {classes} = useRafStyles()
  const {globalState} = useGlobalState()
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const isPNQ = globalState.redirectUrl.origin.includes('pnq')
  const [showCustomerNumberHintDialog, setShowCustomerNumberHintDialog] = useState(false)
  const {fields, append, remove} = useFieldArray({
    name: 'customerIds' as const,
    control: formMethods.control
  })

  return (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '16px', width: '100%'}}>
      <Typography className={classes.title} style={{marginBottom: '16px'}}>
        {t('authenticator.requestAccessSelfOnboarding.companyStep.title')}
      </Typography>
      <Controller
        name="companyName"
        control={formMethods.control}
        render={({field: {value, onChange}}) => (
          <InputTextField
            id="companyField"
            data-test-id="raf-company-name"
            label={t('authenticator.requestAccessSelfOnboarding.companyStep.form.companyName')}
            type="text"
            helperText={
              formMethods.formState.errors.companyName?.message &&
              t(`authenticator.errorMessages.${formMethods.formState.errors.companyName?.message}`)
            }
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="countryId"
        control={formMethods.control}
        render={({field: {value, onChange}}) => (
          <CountryField
            name="countryId"
            data-test-id="raf-company-step-country-name"
            value={value}
            disabled={isPNQ}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              formMethods.setValue('market', '')
              onChange(e)
            }}
            formMethods={formMethods}
          />
        )}
      />
      <Controller
        name="market"
        control={formMethods.control}
        render={({field: {value, onChange}}) => (
          <MarketField
            name="market"
            value={value}
            onChange={onChange}
            formMethods={formMethods}
            disabled={isPNQ}
            countryFormName="countryId"
          />
        )}
      />
      <Button
        variant="text"
        style={{alignSelf: 'end', textDecoration: 'underline', fontWeight: 500, textAlign: 'start'}}
        onClick={() => setShowCustomerNumberHintDialog(true)}
      >
        {t('authenticator.requestAccessSelfOnboarding.companyStep.form.customerNumberLink.title')}
      </Button>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`customerIds.${index}`}
          control={formMethods.control}
          render={({field: {value, onChange}}) => (
            <Box style={{display: 'flex', width: '100%', gap: '12px'}}>
              <Box style={{flex: 1}}>
                <InputTextField
                  type="text"
                  data-test-id={`raf-company-step-customer-id-${index}`}
                  label={t(
                    'authenticator.requestAccessSelfOnboarding.companyStep.form.customerNumber'
                  )}
                  value={value.id}
                  onChange={(event) => onChange({id: event.currentTarget.value})}
                  helperText={
                    formMethods.formState.errors.customerIds?.[index] &&
                    t(
                      `authenticator.errorMessages.${formMethods.formState.errors.customerIds?.[index]?.id?.message}`
                    )
                  }
                />
              </Box>
              {fields.length > 1 && (
                <IconButton
                  onClick={() => remove(index)}
                  style={{padding: '16px', color: '#29333D'}}
                >
                  <DeleteOutline />
                </IconButton>
              )}
            </Box>
          )}
        />
      ))}
      <Button
        variant="text"
        color="primary"
        onClick={() => append({id: ''})}
        style={{alignSelf: 'flex-start', fontWeight: 500}}
        data-test-id="button-add-new-customer-number"
        startIcon={<Add />}
      >
        {t('authenticator.requestAccessSelfOnboarding.companyStep.form.addCustomerNumber')}
      </Button>
      {showCustomerNumberHintDialog && (
        <CustomerNumberHintDialog
          isOpened={showCustomerNumberHintDialog}
          setIsOpened={setShowCustomerNumberHintDialog}
        />
      )}
    </Box>
  )
}
