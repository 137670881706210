import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '34px',
    color: '#29333D',
    textAlign: 'center'
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#52667A',
    textAlign: 'center'
  },
  rootBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '28px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  buttonsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    }
  }
}))
