import {useLatestDocumentVersion} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {
  HConnectLogoComponent,
  LegalDocumentType,
  TermsAndPrivacyModal,
  Typography
} from '@hconnect/uikit'
import {Box, Link} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useStyles} from '@hconnect/authenticator/src/Pages/CreateAccount/CreateAccount.styles'
import {getBrowserLanguage} from '@hconnect/authenticator/src/utils'

export const TermsAndConditions = (props: {
  country: string
  onTermsSubmit: (tcVersion: string) => void
}) => {
  const {classes} = useStyles()
  const [documentType, setDocumentType] = useState<LegalDocumentType>(LegalDocumentType.NONE)
  const {i18n} = useTranslation()

  const country = props.country

  const latestTcVersionQueryInfo = useLatestDocumentVersion('tc', country?.toLowerCase())
  const latestTcVersion = latestTcVersionQueryInfo?.lastAvailableDocumentVersion?.version

  const languageFromGlobalState = 'de-DE'
  const preferredLanguage = !languageFromGlobalState
    ? getBrowserLanguage()
    : languageFromGlobalState

  useEffect(() => {
    if (documentType !== LegalDocumentType.NONE) {
      props.onTermsSubmit(latestTcVersion as string)
    }
  }, [documentType, latestTcVersion])

  return (
    <Box className={classes.termsCheckbox}>
      <Typography
        variant="subtitle2"
        className={classes.termsCheckboxText}
        style={{fontSize: '14px', lineHeight: '18px'}}
      >
        <Trans i18nKey="authenticator.legal.prefix" />
        <br />
        <Link
          className={classes.link}
          underline="always"
          fontWeight={500}
          onClick={(e) => {
            e.stopPropagation()
            setDocumentType(LegalDocumentType.TERMS)
          }}
        >
          <Trans i18nKey="authenticator.legal.terms and conditions" />
        </Link>
        &nbsp;
        <Trans i18nKey="authenticator.requestAccessSelfOnboarding.summaryStep.legal.between" />
        &nbsp;
        <Link
          className={classes.link}
          underline="always"
          fontWeight={500}
          onClick={(e) => {
            e.stopPropagation()
            setDocumentType(LegalDocumentType.PRIVACY)
          }}
        >
          <Trans i18nKey="authenticator.legal.privacy" />
        </Link>
        .
      </Typography>
      <TermsAndPrivacyModal
        logo={HConnectLogoComponent}
        documentType={documentType}
        onClose={() => {
          setDocumentType(LegalDocumentType.NONE)
        }}
        onAccept={() => {
          setDocumentType(LegalDocumentType.NONE)
        }}
        country={country}
        showFooter={false}
        hideAcceptanceTitle={true}
        preferredLanguage={preferredLanguage}
        onLanguageChange={(languageCode: string) => {
          void i18n.changeLanguage(languageCode)
        }}
      />
    </Box>
  )
}
