import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    border: '1px solid #E8ECF0',
    boxShadow: '0px 2px 32px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.04)'
  },
  infoText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D',
    textAlign: 'center'
  }
}))
