import {Feature, isFeatureEnabled} from '@hconnect/apiclient'
import React, {useEffect, useState} from 'react'

import {api} from '../../api/api'
import {FeaturesContext} from '../../context/features/FeaturesContext'
import {useGlobalState} from '../../hooks/useGlobalState'
import {getBannerCountryIdsFromRedirectUrl, getCountryIdFromLng} from '../../utils'

type FeaturesProviderProps = {
  children: React.ReactNode
}
export const FeaturesProvider = ({children}: FeaturesProviderProps) => {
  const {globalState} = useGlobalState()
  const {clientId, redirectUrl, country, lng} = globalState
  const [features, setFeatures] = useState<{
    announcementBannerEnabled: boolean
    oldLoginFlowEnabled: boolean
    requestAccessFormEnabled: boolean
    selfOnboardingRafEnabled: boolean
  }>()
  const handleCheckFeatures = async () => {
    const loginFlowsResponse = await api.get<Feature[]>('/features')

    const countriesIds: string[] = country
      ? [country]
      : lng
        ? [getCountryIdFromLng(lng)]
        : redirectUrl
          ? [...getBannerCountryIdsFromRedirectUrl(redirectUrl)]
          : []

    const shouldDisplayAnnouncementBanner = !!countriesIds.find((countryId) => {
      return isFeatureEnabled(
        loginFlowsResponse.data,
        {country: countryId, clientId},
        'MaintenanceAnnoucementBanner'
      )
    })

    const shouldDisplayOldLoginFlow = isFeatureEnabled(
      loginFlowsResponse.data,
      {clientId},
      'OldLoginFlow'
    )

    const shouldDisplayRequestAccessForm = isFeatureEnabled(
      loginFlowsResponse.data,
      {countryId: country},
      'LandingPageRequestAccess'
    )

    const shouldDisplaySelfOnboardingRAF = !!countriesIds.find((countryId) => {
      return isFeatureEnabled(
        loginFlowsResponse.data,
        {countryId, clientId: clientId},
        'EnableSelfOnboarding'
      )
    })

    setFeatures({
      announcementBannerEnabled: shouldDisplayAnnouncementBanner,
      oldLoginFlowEnabled: shouldDisplayOldLoginFlow,
      requestAccessFormEnabled: shouldDisplayRequestAccessForm,
      selfOnboardingRafEnabled: shouldDisplaySelfOnboardingRAF
    })
  }

  useEffect(() => {
    void handleCheckFeatures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FeaturesContext.Provider
      value={{
        shouldDisplayAnnouncementBanner: features?.announcementBannerEnabled || false,
        shouldDisplayOldLoginFlow: features?.oldLoginFlowEnabled || false,
        shouldDisplayRequestAccessForm: features?.requestAccessFormEnabled || false,
        shouldDisplaySelfOnboardingRAF: features?.selfOnboardingRafEnabled || false
      }}
    >
      {children}
    </FeaturesContext.Provider>
  )
}
