import {Product} from '@hconnect/apiclient'
import {Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material'
import React, {useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {clientSecrets} from './constants'
import {routes} from './routes'

const useStyles = makeStyles()(() => ({
  select: {
    borderRadius: '26px',
    '& .MuiSelect-select': {
      padding: '10px'
    }
  }
}))
export const RedirectUrls = () => {
  const [clientId, setClientId] = useState<Product>(Product.HConnect)
  const clientSecret = clientSecrets.HConnect
  const state = '1234'
  const redirectUri = 'https://manage-qa.hconnect.digital'
  const queryString = `?client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}&state=${state}`
  const {classes} = useStyles()
  return (
    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
      <Typography variant="caption">Product</Typography>
      <FormControl>
        <Select
          value={clientId}
          onChange={(event: SelectChangeEvent<Product>) =>
            setClientId(event.target.value as Product)
          }
          className={classes.select}
        >
          <MenuItem value={'OnSite'}>OnSite</MenuItem>
          <MenuItem value={'Cockpit'}>Cockpit</MenuItem>
          <MenuItem value={'Hub'}>Hub</MenuItem>
          <MenuItem value={'TruckMonitor'}>TruckMonitor</MenuItem>
          <MenuItem value={'HConnect'}>HConnect</MenuItem>
          <MenuItem value={'TruckMonitor'}>UserManagement</MenuItem>
          <MenuItem value={'HCEM'}>HCEM</MenuItem>
          <MenuItem value={'HProduce'}>HProduce</MenuItem>
          <MenuItem value={'Maintain'}>Maintain</MenuItem>
          <MenuItem value={'POM'}>POM</MenuItem>
          <MenuItem value={'Invitation'}>Invitation</MenuItem>
          <MenuItem value={'Settings'}>Settings</MenuItem>
          <MenuItem value={'CarbonBank'}>CarbonBank</MenuItem>
        </Select>
      </FormControl>
      <ul>
        {Object.values(routes).map((route, index) => {
          const url = route + encodeURI(queryString)
          return (
            <li key={index}>
              <a href={url}>{route}</a>
            </li>
          )
        })}
      </ul>
    </Box>
  )
}
