import {Box, Button, ButtonProps, CircularProgress} from '@mui/material'
import clsx from 'clsx'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {requestAccessMaxStepIndex} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {RequestAccessSelfOnboardingFormData} from '../../Pages/RequestAccessSelfOnboarding/RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../Pages/RequestAccessSelfOnboarding/styles'

type StepFormProps = ButtonProps & {
  submitText?: string
  onSubmit?: () => void
  stepFieldNames?: (keyof RequestAccessSelfOnboardingFormData)[]
  isLoading?: boolean
}

export const StepForm = ({
  submitText,
  onSubmit,
  stepFieldNames,
  isLoading,
  ...otherProps
}: StepFormProps) => {
  const {globalState, setGlobalState} = useGlobalState()
  const {classes} = useRafStyles()
  const index = globalState.requestAccessStepperIndex
  const {t} = useTranslation()
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const setRequestAccessStep = (newStepIndex: number) => {
    if (newStepIndex > requestAccessMaxStepIndex) {
      return
    }
    setGlobalState((g) => ({
      ...g,
      requestAccessStepperIndex: newStepIndex
    }))
  }

  const handleBack = () => {
    setRequestAccessStep(index - 1)
  }

  const handleSubmit = async () => {
    const validForm = stepFieldNames ? await formMethods.trigger(stepFieldNames) : true

    if (validForm) {
      formMethods.clearErrors()
      setRequestAccessStep(index + 1)
    }

    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <Box style={{display: 'flex', gap: '12px', justifyContent: 'center'}}>
      {index > 0 ? (
        <Button onClick={handleBack} className={clsx(classes.secondaryButton, classes.backButton)}>
          {t('authenticator.requestAccessSelfOnboarding.goBack')}
        </Button>
      ) : null}
      {submitText && (
        <Button
          onClick={handleSubmit}
          data-test-id={`raf-step-${index}-submit`}
          style={{backgroundColor: 'rgba(1, 106, 212, 1)', color: '#FFFFFF', whiteSpace: 'nowrap'}}
          startIcon={isLoading ? <CircularProgress size={20} style={{color: '#FFFFFF'}} /> : null}
          disabled={isLoading}
          {...(onSubmit && {type: 'submit'})}
          {...otherProps}
        >
          {submitText}
        </Button>
      )}
    </Box>
  )
}
