import {Box, Button, Typography} from '@mui/material'
import clsx from 'clsx'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {routes} from '../../routes'

import {ContactUsUrl} from './RequestAccessSelfOnboarding.types'
import {useStyles} from './RequestAccessSelfOnboardingSuccess.styles'
import {useRafStyles} from './styles'

import {ReactComponent as RegistrationFinal} from '@hconnect/authenticator/src/Assets/raf_final.svg'

export const RequestAccessSelfOnboardingSuccess = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {classes: generalClasses} = useRafStyles()
  const history = useHistory()

  const handleContactUsLink = () => {
    window.open(ContactUsUrl, '_blank', 'noopener,noreferrer')
  }

  const handleGoToLoginPage = () =>
    history.push({pathname: routes.SignIn, search: window.location.search})

  return (
    <Box className={classes.rootBox} data-test-id="request-access-success">
      <RegistrationFinal />
      <Typography className={classes.title}>
        {t('authenticator.requestAccessSelfOnboarding.success.title')}
      </Typography>
      <Typography
        className={classes.subTitle}
        style={{
          marginTop: '16px'
        }}
      >
        {t('authenticator.requestAccessSelfOnboarding.success.subtitle')}
      </Typography>
      <Box className={classes.buttonsBox}>
        <Button
          className={clsx(generalClasses.primaryButton, generalClasses.goToLoginPageButton)}
          onClick={handleGoToLoginPage}
          data-test-id="go-to-login-button"
        >
          {t('authenticator.requestAccessSelfOnboarding.success.goToLoginPage')}
        </Button>
        <Button
          className={clsx(generalClasses.secondaryButton, generalClasses.contactUsButton)}
          onClick={handleContactUsLink}
          data-test-id="contact-us-button"
        >
          {t('authenticator.requestAccessSelfOnboarding.success.contactUs')}
        </Button>
      </Box>
    </Box>
  )
}
