import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  inputTextWrapper: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  multilineText: {
    borderRadius: '4px',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    padding: '6px 16px',
    overflowY: 'auto',
    maxHeight: '300px',
    '& .MuiInputLabel-root': {
      padding: '8px 18px'
    }
  }
}))
