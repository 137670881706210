import {makeStyles} from 'tss-react/mui'

export const useRafStyles = makeStyles()((theme) => ({
  primaryButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    placeSelf: 'center',
    fontWeight: 500,
    backgroundColor: '#016AD4',
    color: '#FFFFFF',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& .MuiButtonBase-root': {
        width: '100%'
      }
    },
    '&:hover': {
      backgroundColor: '#0162C4'
    }
  },
  secondaryButton: {
    whiteSpace: 'nowrap',
    borderRadius: '6px',
    border: '1px solid #E8ECF0',
    color: '#29333D',
    outline: 'none',
    fontWeight: 500,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      border: '1px solid transparent'
    }
  },
  title: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D'
  },
  subTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D'
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#29333D'
  },
  confirmButton: {
    marginTop: '24px',
    marginBottom: '12px'
  },
  goToLoginPageButton: {},
  backButton: {
    padding: 0
  },
  contactUsButton: {
    placeSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
