/* eslint-disable @typescript-eslint/unbound-method */
import {getMarketLabelForGermany} from '@hconnect/common/utils/markets'
import {sortByPriority} from '@hconnect/common/utils/sorting'
import {InputTextField} from '@hconnect/uikit/src/lib2'
import {TextFieldProps} from '@mui/material'
import React, {ChangeEventHandler} from 'react'
import {FieldValues, Path, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useGlobalState} from '../../hooks/useGlobalState'
import {useStyles} from '../../Pages/CreateAccount/CreateAccount.styles'

import {useAvailableCountries} from './useCountries'

export type MarketFieldProps<T extends FieldValues> = TextFieldProps & {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  formMethods: UseFormReturn<T>
  name: Path<T>
  countryFormName: Path<T>
  disabled?: boolean
}

export const MarketField = <T extends FieldValues>({
  name,
  value,
  onChange,
  formMethods,
  disabled,
  countryFormName,
  ...otherProps
}: MarketFieldProps<T>) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const availableCountries = useAvailableCountries()
  const {globalState} = useGlobalState()

  const watchedCountry = formMethods.watch(countryFormName)

  const selectedCountry = availableCountries?.find(
    (country) => country.countryCode === watchedCountry
  )

  const gerBusinessLinePriority = {
    Zement: 1,
    Mineralik: 2,
    Beton: 3
  }

  const marketSelectValues =
    selectedCountry?.markets
      .filter(
        (market) =>
          market.products === null ||
          market.products.length === 0 ||
          market.products.filter(
            (product) => product.toLowerCase() === globalState.clientId.toLowerCase()
          ).length > 0
      )
      .map((market) => ({
        value: market.marketId,
        label:
          selectedCountry.countryCode === 'DE'
            ? getMarketLabelForGermany(market)
            : market.marketName
      }))
      .sort((a, b) => {
        if (selectedCountry.countryCode === 'DE') {
          const regex: RegExp = /\(([^)]+)\)/
          const businessLineA = regex.exec(a.label)?.[1] || ''
          const businessLineB = regex.exec(b.label)?.[1] || ''

          return sortByPriority(
            businessLineA,
            businessLineB,
            a.label,
            b.label,
            gerBusinessLinePriority
          )
        } else {
          return 0
        }
      }) || []

  return (
    <InputTextField
      id="marketField"
      name={name}
      value={value}
      data-test-id="market-field"
      onChange={onChange}
      error={!!formMethods.formState.errors?.[name]}
      helperText={
        !!formMethods.formState.errors?.[name] &&
        t(`authenticator.errorMessages.${formMethods.formState.errors?.[name]?.message}`)
      }
      disabled={disabled || !watchedCountry}
      label={t('authenticator.requestAccess.textfieldLabels.market')}
      menuItemsStyle={classes.wrappedTextField}
      type="text"
      select
      selectValues={marketSelectValues}
      {...otherProps}
    />
  )
}
