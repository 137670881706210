import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  summarySection: {
    marginBottom: '12px',
    marginTop: '16px',
    justifyContent: 'space-between',
    width: '100%'
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#29333D',
    padding: '8px 0px'
  },
  editContainer: {
    padding: '8px 12px',
    color: '#016AD4',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: '500'
  }
}))
