import {Box, Typography} from '@mui/material'
import React from 'react'

import {useStyles} from './SummaryField.styles'

type SummaryFieldProps = {
  title: string
  value?: string
  children?: React.ReactNode
}

export const SummaryField: React.FC<SummaryFieldProps> = (props) => {
  const {classes} = useStyles()

  return (
    <Box sx={{marginBottom: '12px'}}>
      <Typography className={classes.fieldTitle}>{props.title}</Typography>
      {props.value ? (
        <Typography variant="body1" className={classes.fieldValue}>
          {props.value}
        </Typography>
      ) : (
        props.children
      )}
    </Box>
  )
}
