import {isFeatureEnabled} from '@hconnect/apiclient'
import React, {useEffect} from 'react'
import {Route, useHistory} from 'react-router-dom'

import {useAuthFeatures} from './hooks/features/useAuthFeatures'
import {useGlobalState} from './hooks/useGlobalState'
import {Account} from './Pages/Account/Account'
import {CreateAccount} from './Pages/CreateAccount/CreateAccount'
import {CreateAccountSuccess} from './Pages/CreateAccount/CreateAccountSuccess'
import {ErrorPage} from './Pages/ErrorPage'
import {Confirmation} from './Pages/RequestAccess/Confirmation'
import {RequestAccess} from './Pages/RequestAccess/RequestAccess'
import {RussianRequestAccessPage} from './Pages/RequestAccess/RussianRequestAccessPage'
import {RequestAccessSelfOnboarding} from './Pages/RequestAccessSelfOnboarding/RequestAccessSelfOnboarding'
import {RequestAccessSelfOnboardingSuccess} from './Pages/RequestAccessSelfOnboarding/RequestAccessSelfOnboardingSuccess'
import {ResetPasswordLegacy} from './Pages/ResetPassword/ResetPasswordLegacy'
import {SignIn} from './Pages/SignIn/SignIn'
import {StepChangePassword} from './Pages/SignIn/StepChangePassword'
import {StepChangePasswordSuccess} from './Pages/SignIn/StepChangePasswordSuccess'
import {StepCheckMail} from './Pages/SignIn/StepCheckMail'
import {StepEmailRedirect} from './Pages/SignIn/StepEmailRedirect'
import {StepInsertCode} from './Pages/SignIn/StepInsertCode'
import {StepVerifyUserData} from './Pages/SignIn/StepVerifyUserData'
import {UserLocked} from './Pages/SignIn/UserLocked'
import VerifyUserData from './Pages/VerifyUserData/VerifyUserData'
import VersionInfo from './Pages/VersionInfo'
import {RedirectUrls} from './RedirectUrls'
import {routes} from './routes'
import {TraceHistory} from './TraceHistory'

const Root = () => {
  const history = useHistory()
  const {globalState} = useGlobalState()
  const {registrationType} = globalState

  useEffect(() => {
    if (!registrationType) {
      return
    }

    if (registrationType === 'request-access') {
      history.replace('/request-access')
      return
    }
    if (registrationType === 'create-account') {
      history.replace('/create-account')
      return
    }
  }, [registrationType])

  return <SignIn />
}

export const Router: React.FC = () => {
  const {shouldDisplaySelfOnboardingRAF} = useAuthFeatures()

  return (
    <>
      <TraceHistory />
      <Route path={[routes.Account]} render={() => <Account />} />
      <Route path={[routes.SignIn, routes.Password]} render={() => <Root />} />
      <Route
        path={[routes.RequestAccess, routes.Register]}
        exact
        render={() =>
          shouldDisplaySelfOnboardingRAF ? <RequestAccessSelfOnboarding /> : <RequestAccess />
        }
      />
      <Route
        path={[routes.RequestAccessSuccess]}
        exact
        render={() =>
          shouldDisplaySelfOnboardingRAF ? <RequestAccessSelfOnboardingSuccess /> : <Confirmation />
        }
      />
      <Route path={[routes.CreateAccount]} exact render={() => <CreateAccount />} />
      <Route path={[routes.CreateAccountSuccess]} exact render={() => <CreateAccountSuccess />} />
      <Route path={[routes.VerifyUserData]} exact render={() => <VerifyUserData />} />
      <Route
        path={[routes.InsertQuickCode, routes.ResetPassword]}
        exact
        render={() => <StepInsertCode />}
      />
      <Route path={[routes.ResetPasswordLegacy]} exact render={() => <ResetPasswordLegacy />} />
      <Route path={[routes.CheckMail]} exact render={() => <StepCheckMail />} />
      <Route path={[routes.MailRedirect]} exact render={() => <StepEmailRedirect />} />
      <Route path={[routes.ChangePassword]} exact render={() => <StepChangePassword />} />
      <Route
        path={[routes.RussianRequestAccessPage]}
        exact
        render={() => <RussianRequestAccessPage />}
      />
      <Route
        path={[routes.ChangePasswordSuccess]}
        exact
        render={() => <StepChangePasswordSuccess />}
      />
      <Route
        path={[routes.RequestUserDataVerification]}
        exact
        render={() => <StepVerifyUserData />}
      />
      <Route path={[routes.UserLocked]} exact render={() => <UserLocked />} />
      <Route path={[routes.Error]} exact render={() => <ErrorPage />} />

      <Route path="/redirect" exact render={() => <div>REDIRECTED</div>} />
      <Route path="/version" exact render={() => <VersionInfo />} />
      {process.env.NODE_ENV === 'development' && (
        <Route path="/redirect-urls" exact render={() => <RedirectUrls />} />
      )}
    </>
  )
}
